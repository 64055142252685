import XYZBaseMap from './XYZBaseMap';
import MapSettings from './MapSettings';
import MapLayerWMS from './MapLayerWMS';
import MapLayerVector from './MapLayerVector';

import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

/*****************************
* MapHandler
*
* class for showing map and data on 
* it
*****************************/
export default class MapHandlerPublic {
    /*****************************
    * constructor
    *
    *****************************/    
    constructor() {
        this.baseMap = null;
        this.host = null;
        this.mapSettings = null;
        if(location.host.substring(0,3) != "192"){
            this.host = "http://54.229.107.133"
        } else {
            this.host = "http://192.168.1.249"
            
        }
        this.createMap();
    }
    /*****************************
    * createMap
    *
    *****************************/
    createMap() {
        var mapSettings = new MapSettings().settings;
        mapSettings.resolutions = [1024,512,256.0,128.0,64.0,32.0,16.0,8.0,4.0];
        mapSettings.restrictedExtent = [59003, 7055132, 953553,7798148];
        mapSettings.initCenterLat = 7443880;
        mapSettings.initCenterLon = 521561;
        mapSettings.opacity = 0.5;
        this.baseMap = new XYZBaseMap(mapSettings);
        this.mapSettings = mapSettings;
        
        this.createLayers();
        this.baseMap.showMap();
        //this.addMapPositionLayer();
    }
        
        /*****************************
    * createLayers
    *
    *****************************/
    createLayers() {
      var layer = null;
        //// REINDEER ACCIDENTS
        layer = new MapLayerWMS("accidents",
            true,
            2,
            0,
            this.host + "/maps/ovl/001",
            this.mapSettings.maxExtent,
            ["accidents_last"]);
        this.baseMap.mapObject.addLayer(layer);
        
        layer = new MapLayerWMS("notices",
            true,
            1,
            0,
            this.host + "/maps/ovl/001",
            this.mapSettings.maxExtent,
            ["notices_last"]);
        this.baseMap.mapObject.addLayer(layer);
        
        layer = new MapLayerWMS("risk_roads",
            true,
            0,
            0,
            this.host + "/maps/ovl/001",
            this.mapSettings.maxExtent,
            ["accident_risk_area_roads"]);
        this.baseMap.mapObject.addLayer(layer);
    }
    
    /*****************************
    * addMapPositionLayer
    *
    *****************************/
    addMapPositionLayer() {
        let style =  new Style({
            image: new CircleStyle({
                radius: 8,
                stroke: new Stroke({
                    width: 3,
                    color: [255,255,255,1]
                }),
                fill: new Fill({
                      color: [60,140,255,1]
                })
            })
        })
        var layer = new MapLayerVector("MapPositionLayer",false,20,0,style);
        layer.getSource().addFeature(new Feature(new Point([0,0])));
        this.baseMap.mapObject.addLayer(layer);
    }
    
    /*****************************
    * getLayerByName
    *
    *****************************/
    getLayerByName(name) {
        var targetLayer;
        var layers = this.baseMap.mapObject.getLayers().getArray();
        for (var i in layers) {
            if (typeof layers[i].get('title') != 'undefined') {
                if ( layers[i].get('title') == name ) {
                    targetLayer = layers[i];
                    break;
                }
            }
        }
        return targetLayer;
    }
}
