require('jquery')
require('ol')
import './styles/ol_import.scss'

import $ from 'jquery';
import MapHandlerPublic from './ol/MapHandlerPublic';
//import MapPosition from './ol/MapPosition';
import AccidentGraph from './init/AccidentGraph';

$(document).ready(function() {
    var mapHandler = new MapHandlerPublic();
    var graph = new AccidentGraph();
    graph.initialize();
    //var mapPosition = new MapPosition(mapHandler)
});
