export default class MapSettings {
    constructor() {
        this.settings = {
            mapContainer: 'map', // map element id to attach map to
	        maxExtent: [-548576,6291456,1548576,8388608], //to calculate tile and y
	        projection: "EPSG:3067",
	        displayProjection: "EPSG:3067",
	        resolutions: [8192.0,4096.0,2048.0,1024.0,512.0,256.0,128.0,64.0,32.0,16.0,8.0,4.0,2.0,1.0,0.5,0.25],
	        serveresolutions: [8192.0,4096.0,2048.0,1024.0,512.0,256.0,128.0,64.0,32.0,16.0,8.0,4.0,2.0,1.0],
	        //mapUrls: ["http://185.20.136.127/maps/tl/1.0.0/000/{z}/{x}/{y}.png"],
	        //mapUrls: ["http://185.26.48.134/tileserver/tl/1.0.0/000/{z}/{x}/{y}.png"],
          mapUrls: ["https://wms.poloymap.fi/tl/1.0.0/000/{z}/{x}/{y}.png"],
	        initZoomLevel: 0,
	        restrictedExtent: [-548576,6291456,1548576,8388608],
	        opacity: 1.0,
	        imageFormat: 'png',
	        baseLayerName: 'base',
	        initCenterLat: 7453880, // beginning position of latitude axis
	        initCenterLon: 501561, // beginning position of longitude axis
            copyrightLabel: 'Pohjakartta © MML, 2018' // label for attribution control
        }
    }    
}
