import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

/*****************************
* MapLayerWMS
*
* class for showing wsm layer on 
* map 
*****************************/

export default class MapLayerWMS extends ImageLayer {
    /*****************************
    * constructor
    * 
    *****************************/    
    constructor(name, 
                visibility,
                zIndex,
                minZoom, 
                mapUrl,
                extent,
                wmsLayers,
                additionalParams = null) {
        var wms_params = {
            'NAME': name, 
            'LAYERS': wmsLayers, 
            'FORMAT': 'png',
            'TRANSPARENCY': true,
            'VERSION': '1.1.1'
        };
        if (additionalParams != null){      
            for (var key in additionalParams) {
                wms_params[key] = additionalParams[key];
            }
        }

        var source = new ImageWMS({
            url: mapUrl,
            params: wms_params,
            ratio: 1,
            serverType: 'mapserver'
        });
        //todo extent
        super({
            title: name,
            extent: extent,
            source: source,
            visible: visibility,
            zIndex: zIndex,
            minZoom: minZoom
        });
   }
}
