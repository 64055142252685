import $ from 'jquery';
// AmCharts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// AmCharts Theme
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/***************************************
 * createGraph()
 * creates graph
 ****************************************/
export default class AccidentGraph {
  constructor() {
    
    am4core.options.commercialLicense = true; // hide AmCharts logo
    am4core.useTheme(am4themes_dark);
//    am4core.useTheme(am4themes_animated); // Enable animations
    
    this.graphMonthlyData=null;
    this.graphCumulativeData=null;
  }

/***************************************
 * initialize()
 * initializes graph
 ****************************************/
  initialize(community) {
    //this.createMonthlyGraph();
    
    $.ajax({
        url: "/stats/total",
        type: 'get',
        dataType: "json",
        data: {community: community},
        success: this.grapDataResp,
        context: this 
    });
  }
  
/***************************************
 * crateMonthlyGraph()
 * creates graph
 ****************************************/
  createMonthlyGraph() {
    let color;
    let chart = am4core.create("chart_monthly", am4charts.XYChart);
    chart.data = this.graphMonthlyData;
    let title = chart.titles.create();
    title.text = "Porokolarit kuukausittain";
    title.fontSize = 18;
    /*
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    */
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.language = new am4core.Language("fi_FI");
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.useDefaultMarker = true;
    
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    
    let series11 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#6d6d6d");
    series11.name = "2011";
    series11.dataFields.categoryX = "date";
    series11.dataFields.valueY = "2011";
    series11.strokeWidth = 2;
    series11.stroke = color;
    series11.fill = color;
    series11.tooltipText = "{name}: {valueY}";
    
    let series12 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#d4e79a");
    //color = am4core.color("#8bb315");
    series12.name = "2012";
    series12.dataFields.categoryX = "date";
    series12.dataFields.valueY = "2012";
    series12.strokeWidth = 2;
    series12.stroke = color;
    series12.fill = color;
    series12.tooltipText = "{name}: {valueY}";
    
    let series13 = chart.series.push(new am4charts.LineSeries());
    
    color = am4core.color("#91e6e6");
    //color = am4core.color("#17bdbd");
    series13.name = "2013";
    series13.dataFields.categoryX = "date";
    series13.dataFields.valueY = "2013";
    series13.strokeWidth = 2;
    series13.stroke = color;
    series13.fill = color;
    series13.tooltipText = "{name}: {valueY}";
    
    let series14 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#90c0e6");
    //color = am4core.color("#1277c5");
    series14.name = "2014";
    series14.dataFields.categoryX = "date";
    series14.dataFields.valueY = "2014";
    series14.strokeWidth = 2;
    series14.stroke = color;
    series14.fill = color
    series14.tooltipText = "{name}: {valueY}";
    
    let series15 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffffff");
    series15.name = "2015";
    series15.dataFields.categoryX = "date";
    series15.dataFields.valueY = "2015";
    series15.strokeWidth = 2;
    series15.stroke = color;
    series15.fill = color;
    series15.tooltipText = "{name}: {valueY}";
    
    let series16 = chart.series.push(new am4charts.LineSeries());
    
    color = am4core.color("#c772e3")
    //color = am4core.color("#C390D4")
    series16.name = "2016";
    series16.dataFields.categoryX = "date";
    series16.dataFields.valueY = "2016";
    series16.strokeWidth = 2;
    series16.stroke = color;
    series16.fill = color;
    series16.tooltipText = "{name}: {valueY}";
    
    let series17 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#e65ea8");
    //color = am4core.color("#FAF7B1");
    series17.name = "2017";
    series17.dataFields.categoryX = "date";
    series17.dataFields.valueY = "2017";
    series17.strokeWidth = 2;
    series17.stroke = color;
    series17.fill = color;
    series17.tooltipText = "{name}: {valueY}";
    
    let series18 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#c2486b");
    //color = am4core.color("#ff0000");
    series18.name = "2018";
    series18.dataFields.categoryX = "date";
    series18.dataFields.valueY = "2018";
    series18.strokeWidth = 2;
    series18.stroke = color;
    series18.fill = color;
    series18.tooltipText = "{name}: {valueY}";
    
    let series19 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ff2222");
    //color = am4core.color("#900C3F");
    series19.name = "2019";
    series19.dataFields.categoryX = "date";
    series19.dataFields.valueY = "2019";
    series19.strokeWidth = 2;
    series19.stroke = color;
    series19.fill = color;
    series19.tooltipText = "{name}: {valueY}";
    
    let series20 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffa948");
    //color = am4core.color("#208C3F");
    series20.name = "2020";
    series20.dataFields.categoryX = "date";
    series20.dataFields.valueY = "2020";
    series20.strokeWidth = 2;
    series20.stroke = color;
    series20.fill = color;
    series20.tooltipText = "{name}: {valueY}";
    
    let series21 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffe148");
    //color = am4core.color("#90507F");
    
    series21.name = "2021";
    series21.dataFields.categoryX = "date";
    series21.dataFields.valueY = "2021";
    series21.strokeWidth = 2;
    series21.stroke = color;
    series21.fill = color;
    series21.tooltipText = "{name}: {valueY}";
  }


/***************************************
 * createMonthlyGraph()
 * creates graph
 ****************************************/
  createCumulativeGraph() {
    let color;
    let chart = am4core.create("chart_cumulative", am4charts.XYChart);
    chart.data = this.graphCumulativeData;
    let title = chart.titles.create();
    title.text = "Porokolarit kuukausittain (kumulatiivinen)";
    title.fontSize = 18;
    /*
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    */
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.language = new am4core.Language("fi_FI");
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0);

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      
    let series11 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#6d6d6d");
    series11.name = "2011";
    series11.dataFields.categoryX = "date";
    series11.dataFields.valueY = "2011";
    series11.strokeWidth = 2;
    series11.stroke = color;
    series11.fill = color;
    series11.tooltipText = "{name}: {valueY}";
    
    let series12 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#d4e79a");
    //color = am4core.color("#8bb315");
    series12.name = "2012";
    series12.dataFields.categoryX = "date";
    series12.dataFields.valueY = "2012";
    series12.strokeWidth = 2;
    series12.stroke = color;
    series12.fill = color;
    series12.tooltipText = "{name}: {valueY}";
    
    let series13 = chart.series.push(new am4charts.LineSeries());
    
    color = am4core.color("#91e6e6");
    //color = am4core.color("#17bdbd");
    series13.name = "2013";
    series13.dataFields.categoryX = "date";
    series13.dataFields.valueY = "2013";
    series13.strokeWidth = 2;
    series13.stroke = color;
    series13.fill = color;
    series13.tooltipText = "{name}: {valueY}";
    
    let series14 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#90c0e6");
    //color = am4core.color("#1277c5");
    series14.name = "2014";
    series14.dataFields.categoryX = "date";
    series14.dataFields.valueY = "2014";
    series14.strokeWidth = 2;
    series14.stroke = color;
    series14.fill = color
    series14.tooltipText = "{name}: {valueY}";
    
    let series15 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffffff");
    series15.name = "2015";
    series15.dataFields.categoryX = "date";
    series15.dataFields.valueY = "2015";
    series15.strokeWidth = 2;
    series15.stroke = color;
    series15.fill = color;
    series15.tooltipText = "{name}: {valueY}";
    
    let series16 = chart.series.push(new am4charts.LineSeries());
    
    color = am4core.color("#c772e3")
    //color = am4core.color("#C390D4")
    series16.name = "2016";
    series16.dataFields.categoryX = "date";
    series16.dataFields.valueY = "2016";
    series16.strokeWidth = 2;
    series16.stroke = color;
    series16.fill = color;
    series16.tooltipText = "{name}: {valueY}";
    
    let series17 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#e65ea8");
    //color = am4core.color("#FAF7B1");
    series17.name = "2017";
    series17.dataFields.categoryX = "date";
    series17.dataFields.valueY = "2017";
    series17.strokeWidth = 2;
    series17.stroke = color;
    series17.fill = color;
    series17.tooltipText = "{name}: {valueY}";
    
    let series18 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#c2486b");
    //color = am4core.color("#ff0000");
    series18.name = "2018";
    series18.dataFields.categoryX = "date";
    series18.dataFields.valueY = "2018";
    series18.strokeWidth = 2;
    series18.stroke = color;
    series18.fill = color;
    series18.tooltipText = "{name}: {valueY}";
    
    let series19 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ff2222");
    //color = am4core.color("#900C3F");
    series19.name = "2019";
    series19.dataFields.categoryX = "date";
    series19.dataFields.valueY = "2019";
    series19.strokeWidth = 2;
    series19.stroke = color;
    series19.fill = color;
    series19.tooltipText = "{name}: {valueY}";
    
    let series20 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffa948");
    //color = am4core.color("#208C3F");
    series20.name = "2020";
    series20.dataFields.categoryX = "date";
    series20.dataFields.valueY = "2020";
    series20.strokeWidth = 2;
    series20.stroke = color;
    series20.fill = color;
    series20.tooltipText = "{name}: {valueY}";
    
    let series21 = chart.series.push(new am4charts.LineSeries());
    color = am4core.color("#ffe148");
    //color = am4core.color("#90507F");
    
    series21.name = "2021";
    series21.dataFields.categoryX = "date";
    series21.dataFields.valueY = "2021";
    series21.strokeWidth = 2;
    series21.stroke = color;
    series21.fill = color;
    series21.tooltipText = "{name}: {valueY}";
  }
/***************************************
 * grapDataResp()
 * ajax response
 ****************************************/
  grapDataResp(data) {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth(); //January => 0

    this.graphMonthlyData = new Array;
    this.graphCumulativeData = new Array;
    for (var i = 0;i < 12;i++) {
      this.graphMonthlyData.push(new Object);
      this.graphCumulativeData.push(new Object);
    }
    var thisObj = this;
    thisObj.graphMonthlyData[0].date='Tammi';
    thisObj.graphMonthlyData[1].date='Helmi';
    thisObj.graphMonthlyData[2].date='Maalis';
    thisObj.graphMonthlyData[3].date='Huhti';
    thisObj.graphMonthlyData[4].date='Touko';
    thisObj.graphMonthlyData[5].date='Kesä';
    thisObj.graphMonthlyData[6].date='Heinä';
    thisObj.graphMonthlyData[7].date='Elo';
    thisObj.graphMonthlyData[8].date='Syys';
    thisObj.graphMonthlyData[9].date='Loka';
    thisObj.graphMonthlyData[10].date='Marras';
    thisObj.graphMonthlyData[11].date='Joulu';

    thisObj.graphCumulativeData[0].date='Tammi';
    thisObj.graphCumulativeData[1].date='Helmi';
    thisObj.graphCumulativeData[2].date='Maalis';
    thisObj.graphCumulativeData[3].date='Huhti';
    thisObj.graphCumulativeData[4].date='Touko';
    thisObj.graphCumulativeData[5].date='Kesä';
    thisObj.graphCumulativeData[6].date='Heinä';
    thisObj.graphCumulativeData[7].date='Elo';
    thisObj.graphCumulativeData[8].date='Syys';
    thisObj.graphCumulativeData[9].date='Loka';
    thisObj.graphCumulativeData[10].date='Marras';
    thisObj.graphCumulativeData[11].date='Joulu';

 
    $.each(data, function (index, value) {
      var x = value.graphdata;

      thisObj.graphMonthlyData[0][x.year]=x['m_01']
      thisObj.graphMonthlyData[1][x.year]=x['m_02']
      thisObj.graphMonthlyData[2][x.year]=x['m_03']
      thisObj.graphMonthlyData[3][x.year]=x['m_04']
      thisObj.graphMonthlyData[4][x.year]=x['m_05']
      thisObj.graphMonthlyData[5][x.year]=x['m_06']
      thisObj.graphMonthlyData[6][x.year]=x['m_07']
      thisObj.graphMonthlyData[7][x.year]=x['m_08']
      thisObj.graphMonthlyData[8][x.year]=x['m_09']
      thisObj.graphMonthlyData[9][x.year]=x['m_10']
      thisObj.graphMonthlyData[10][x.year]=x['m_11']
      thisObj.graphMonthlyData[11][x.year]=x['m_12']
      
      thisObj.graphCumulativeData[0][x.year]=x['m_01']
      thisObj.graphCumulativeData[1][x.year]=thisObj.graphCumulativeData[0][x.year]+ x['m_02']      
      thisObj.graphCumulativeData[2][x.year]=thisObj.graphCumulativeData[1][x.year]+ x['m_03']
      thisObj.graphCumulativeData[3][x.year]=thisObj.graphCumulativeData[2][x.year]+ x['m_04']
      thisObj.graphCumulativeData[4][x.year]=thisObj.graphCumulativeData[3][x.year]+ x['m_05']
      thisObj.graphCumulativeData[5][x.year]=thisObj.graphCumulativeData[4][x.year]+ x['m_06']
      thisObj.graphCumulativeData[6][x.year]=thisObj.graphCumulativeData[5][x.year]+ x['m_07']
      thisObj.graphCumulativeData[7][x.year]=thisObj.graphCumulativeData[6][x.year]+ x['m_08']
      thisObj.graphCumulativeData[8][x.year]=thisObj.graphCumulativeData[7][x.year]+ x['m_09']
      thisObj.graphCumulativeData[9][x.year]=thisObj.graphCumulativeData[8][x.year]+ x['m_10']
      thisObj.graphCumulativeData[10][x.year]=thisObj.graphCumulativeData[9][x.year]+ x['m_11']
      thisObj.graphCumulativeData[11][x.year]=thisObj.graphCumulativeData[10][x.year]+ x['m_12']
      if(x.year == year) { //remove months in future
        for (i = month+1; i <= 11;i++ ) {
          delete thisObj.graphMonthlyData[i][x.year];
          delete thisObj.graphCumulativeData[i][x.year];
        }
      }
      //year = year + 1;       
    });
    if (this.graphMonthly == null) {
      this.createMonthlyGraph();
    } else {
      this.graphMonthly.validateData();
    }
    if (this.graphCumulative == null) {
      this.createCumulativeGraph();
    } else {
      this.graphCumulative.validateData();
    }
  }
}
