import $ from 'jquery';
import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Control from 'ol/control/Control';
import MousePosition from 'ol/control/MousePosition';

import * as proj4 from './proj4';
import {get as olProjGet, transform as olProjTransform, fromLonLat as olProjFromLonLat} from 'ol/proj';

import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Modify from 'ol/interaction/Modify';
import DragPan from 'ol/interaction/DragPan';
import Observable from 'ol/Observable';

import Point from 'ol/geom/Point';

import {getLength as olSphereLength}  from 'ol/sphere';
import {register} from 'ol/proj/proj4';

// Default settings for baseMap
export default class MapContainer {
    constructor() {
        // Initialize MapContainer class
        this.initCenterLon = null;
        this.initCenterLat = null;
        this.initZoomLevel = null;
        this.mapSettings = null;
        this.miscSettings = null;
        this.iActionDraw = null;
        this.iActionRuler = null;
        this.iActionModify = null;
        this.iActionSnap = null;
    }
    
    
   
   
/*
#====================
MapContainer::createBaseMap(Object mapSettings,
                            Object miscSettings)
#====================
*/

    createBaseMap (mapSettings, miscSettings) {
        this.mapSettings = mapSettings;
        this.miscSettings = miscSettings;
        proj4.defs("EPSG:3067","+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs");
        register(proj4);
        let obj = olProjGet("EPSG:3067");
        // Define mapView, area of map, resolutions (zoom levels), coordinate projection etc.
        this.mapView = new View({
            extent: mapSettings.restrictedExtent,
            projection: mapSettings.projection,
            resolutions: mapSettings.resolutions,
            enableRotation: true,
            smoothExtentConstraint: false,
            zoom: mapSettings.initZoomLevel
        })

        // Define mapObject, setting target element and view settings
        this.mapObject = new Map ({
            target: mapSettings.mapContainer,
            view: this.mapView
        });
      
      
        // Set zoom levels, viewable area, transparency and other settings to ol.Map object from mapSettings parameter
        if(mapSettings.maxZoomLevel != null) { this.mapObject.maxZoomLevel = mapSettings.maxZoomLevel; }
        if(mapSettings.minZoomLevel != null) { this.mapObject.minZoomLevel = mapSettings.minZoomLevel; }
        if(mapSettings.numZoomLevels != null) { this.mapObject.numZoomLevels = mapSettings.numZoomLevels; }
        if(mapSettings.restrictedExtent != null) { this.mapObject.restrictedExtent = mapSettings.restrictedExtent[0], mapSettings.restrictedExtent[1], mapSettings.restrictedExtent[2], mapSettings.restrictedExtent[3]; }
        if(mapSettings.opacity != null) { this.mapObject.opacity = mapSettings.opacity; }
        if(miscSettings != null) { this.miscSettings = miscSettings; }
      
        // Define coordinate projection, initial view position and zoom level to MapContainer
        this.projection = mapSettings.projection;
        this.displayProjection = mapSettings.displayProjection;
        this.initCenterLon = mapSettings.initCenterLon;
        this.initCenterLat = mapSettings.initCenterLat;
        this.initZoomLevel = mapSettings.initZoomLevel;

    }

/*
#====================
 MapContainer::showMap(Array[x,y] centre_lonlat,
                       Integer init_zoom_level,
                       String argProjection)
#====================
*/

    showMap (centre_lonlat,init_zoom_level,argProjection)  {
        let center_lonlat;
        //default parameter
        if (typeof centre_lonlat == 'undefined' ) {
            center_lonlat =  [this.initCenterLon,this.initCenterLat];
        }
        if (typeof init_zoom_level == 'undefined' ) {
            init_zoom_level =  (this.initZoomLevel != null) ? this.initZoomLevel : 0;
        }
      // Set center and initial zoom level and show the map
        proj4.defs("EPSG:3067","+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs");
        register(proj4);
        let obj = olProjGet("EPSG:3067");
        let center = olProjTransform(center_lonlat, this.displayProjection, this.projection);
        this.mapView.setCenter(center);
        this.mapView.zoom = init_zoom_level;
    }
/*
#====================
 MapContainer::degreesToDisplayProjPoint(sourceX, sourceY)
#====================
*/
  degreesToDisplayProjPoint(sourceX, sourceY) {
    
	  return new Point([sourceX,sourceY]).transform("EPSG:4326","EPSG:3857")
	  }
/*
#====================
 MapContainer::addMousePosition(String target)
#====================
*/
    addMousePosition (target) {
        //todo decrease decimals
        if (typeof target != 'undefined') {
            // Place mouse position on target element
            let mousePos=new MousePosition({target: document.getElementById(target)});
            mousePos.displayProjection = this.displayProjection;
            this.mapObject.addControl(mousePos);
        } else {
            // Place mouse position inside mapView
            let mousePos=new MousePosition();
            mousePos.displayProjection = this.displayProjection;
            this.mapObject.addControl(mousePos);
        }
    };
/*
#====================
 MapContainer::addPanel(ol.layer.Vector targetSource, Array arrTools)
#====================
*/

    
    addPanel (targetLayer,arrTools) {
        var tool;
        let _this = this;
        let targetSource = targetLayer.getSource();
        let helpTooltipElement;
        let helpTooltip;
        let measureTooltipElement;
        let measureTooltip;
        var mapControls = this.mapObject.getControls().getArray();
        var targetControl;
        for (var idx in mapControls) {
            let elementClass = mapControls[idx].element.className;
            if (elementClass.indexOf('ol-toolbar') >= 0) {
                targetControl = mapControls[idx];
            }
        }
        
        this.iActionRuler = new Draw({
            source: targetSource,
            type: "LineString"
        });
        this.iActionSnap = new Snap({source: targetSource});
        this.iActionModify = new Modify({source: targetSource});
        
        // Check if toolbar control already exists, remove it and active interactions
        if (typeof targetControl != 'undefined') {
          tool = document.querySelector("input[name=tool]");
          removeInteractions(true);
          this.mapObject.removeControl(targetControl);
        }
        
        
        function createHelpTooltip() {
            if (helpTooltipElement) {
                helpTooltipElement.parentNode.removeChild(helpTooltipElement);
            }
            helpTooltipElement = document.createElement('div');
            helpTooltipElement.className = 'ol-tooltip hidden';
            helpTooltip = new Overlay({
            element: helpTooltipElement,
                offset: [15, 0],
                positioning: 'center-left'
            });
            _this.mapObject.addOverlay(helpTooltip);
        }


        function createMeasureTooltip() {
            if (measureTooltipElement) {
                measureTooltipElement.parentNode.removeChild(measureTooltipElement);
            }
            measureTooltipElement = document.createElement('div');
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
            measureTooltip = new Overlay({
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center'
          });
          _this.mapObject.addOverlay(measureTooltip);
        }
        
        // OL6 Custom Control definition
        var ToolbarPanel = (function (Control) {
          function ToolbarPanel (opt_options) {
            let options = opt_options || {};
            let element = document.createElement('div');
            element.className = 'ol-toolbar top-right';
            Control.call(this, {
                element: element,
                target: options.target
            });
            
            // Add hidden pan button to prevent null tool value
            // if arrTools is empty
            if (arrTools.length == 0) {
                let toolInput = document.createElement("input");
                toolInput.setAttribute("name", "tool");
                toolInput.setAttribute("type", "radio");
                toolInput.setAttribute("value", "Pan");
                toolInput.setAttribute("style", "display:none");

                // Add label (using tool image as background)
                let label = document.createElement("label");
                label.setAttribute ("class", "ol-toolbar-button drawPan");
                label.setAttribute("id", "lPan");
                label.appendChild(toolInput);
                element.appendChild(label);
                element.style.display = "none";
            } else {
                //let arrTools = ["Pan","Modify","Point","LineString","Polygon","Ruler"]; // Available tools
                // loop through arrTools array, adding buttons to toolbar
                for (let i = 0; i < arrTools.length; i++) {
                  let toolInput = document.createElement("input");
                  toolInput.setAttribute("name", "tool");
                  toolInput.setAttribute("type", "radio");
                  toolInput.setAttribute("value", arrTools[i]);
                  
                  // set checked status for tools (debugging)
                  //if (i == 0) {
                  //    toolInput.setAttribute("checked", true);
                  //}
                  
                  let label = document.createElement("label");
                  label.setAttribute ("class", "ol-toolbar-button draw" + arrTools[i]);
                  label.setAttribute("id", "l" + arrTools[i]);
                  label.appendChild(toolInput);
                  element.appendChild(label);
                }
            }
          }

          if ( Control ) ToolbarPanel.__proto__ = Control;
          ToolbarPanel.prototype = Object.create( Control && Control.prototype );
          ToolbarPanel.prototype.constructor = ToolbarPanel;
          
          return ToolbarPanel;
        } (Control))
        
        _this.mapObject.addControl(new ToolbarPanel());
        
        tool = document.querySelector("input[name=tool]");
        
        function addInteractions() {
            let geometryType = "Point";
            if (tool.value == "Point" || tool.value == "LineString" || tool.value == "Polygon"){
              _this.iActionDraw = new Draw({
                source: targetSource,
                type: tool.value
                 });
              }

            // Set interaction types for tools on change
            switch (tool.value) {
                case "Pan":
                    break;
                case "Modify":
                    _this.mapObject.addInteraction(_this.iActionModify);
                    _this.mapObject.addInteraction(_this.iActionSnap);
                    break;
                case "Point":
                    _this.mapObject.addInteraction(_this.iActionDraw);
                    break;
                case "LineString":
                case "Polygon":
                    _this.mapObject.addInteraction(_this.iActionDraw);
                    _this.mapObject.addInteraction(_this.iActionSnap);
                    break;
                case "Ruler":
                    _this.mapObject.addInteraction(_this.iActionRuler);
                    _this.mapObject.addInteraction(_this.iActionSnap);
                    break;
            }
        }
        
        function removeInteractions(reset) {
            _this.mapObject.removeInteraction(_this.iActionDraw);
            _this.mapObject.removeInteraction(_this.iActionModify);
            _this.mapObject.removeInteraction(_this.iActionSnap);
            _this.mapObject.removeInteraction(_this.iActionRuler);
            if (reset) { 
                tool.value = "Pan";
            }
        }
            // Remove all drawing interactions on tool change and apply new ones
            $("input[name=tool]").change(function() {
                $("#l"+ tool.value).removeClass("toolChecked");
                tool = document.querySelector("input:checked");
                $("#l"+ tool.value).addClass("toolChecked");
                removeInteractions(false);
                addInteractions();
            });
          
        let sketch;
        let pointerMoveHandler = function (evt) {
            if (evt.dragging) {
                return;
            }

            if (sketch) {
                let geom = (sketch.getGeometry());
            }
          
            helpTooltip.setPosition(evt.coordinate);
            helpTooltipElement.classList.remove('hidden');
        };

        this.mapObject.on('pointermove', pointerMoveHandler);
        this.mapObject.getViewport().addEventListener('mouseout', function() {
          helpTooltipElement.classList.add('hidden');
        });

        let formatLength = function (line) {
            let length = olSphereLength(line, {
                projection: "EPSG:3067"
            });
            let output;
            if (length > 100) {
                output = (Math.round(length / 1000 * 100) / 100) +
                          ' ' + 'km';
            } else {
                output = (Math.round(length * 100) / 100) +
                          ' ' + 'm';
            }
            return output;
        };

        createMeasureTooltip();
        createHelpTooltip();

        let listener;
        this.iActionRuler.on('drawstart', function (evt) {
            // set sketch
            sketch = evt.feature;

            // @type {module:ol/coordinate~Coordinate|undefined}
            let tooltipCoord = evt.coordinate;

            listener = sketch.getGeometry().on('change', function (evt) {
                let geom = evt.target;
                let output;
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
                measureTooltipElement.innerHTML = output;
                measureTooltip.setPosition(tooltipCoord);
            });
        }, this);

        this.iActionRuler.on('drawend', function () {
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            measureTooltip.setOffset([0, -7]);
            // unset sketch
            sketch = null;
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            createMeasureTooltip();
            let observer = new Observable();
            observer.un("change", listener);
        }, this);
    }
}


